import React from 'react'
import { Layout, SEO } from '@raylink/common/components'
import DownloadCommon from '../download-common'

const DownloadBusiness = () => {
  return (
    <Layout canonical="download-for-business.html/">
      <SEO
        title="RayLink企业版客户端官方下载-远程控制软件免费下载"
        keywords="企业远程控制软件,远程控制软件,远控软件,远程软件,远程连接软件"
        description="RayLink企业版远程控制软件是一款服务于企业及团队的真高清、超流畅远程控制软件，支持企业级远程办公、远程协助、远程设计等多种场景。超高清画质，10ms无感低延时，媲美本地操作"
        ogUrl="download-for-business.html"
      />
      <DownloadCommon edition="business" />
    </Layout>
  )
}

export default DownloadBusiness
